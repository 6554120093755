/* src/App.css */
.App {
  text-align: center;
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}