/* src/components/VideoList.css */
.video-list {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .video-list h3 {
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  
  .video {
    background-color: #222;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 300px;
  }
  
  .video img {
    width: 100%;
    border-radius: 5px;
  }
  
  .video-info {
    text-align: left;
    margin-top: 10px;
  }
  
  .video-info h4 {
    margin: 5px 0;
    font-size: 1.2rem;
  }
  
  .video-info p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #aaaaaa;
  }