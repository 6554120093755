/* src/components/Header.css */
.header {
    padding: 20px;
    border-bottom: 1px solid #444;
  }
  
  .header h1 {
    margin: 0;
    font-size: 3rem;
  }
  
  .header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #aaaaaa;
  }